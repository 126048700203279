import {
  AD_STRENGTH,
  MEDIA_ID,
  RSAProduct,
  RSAProductAsset,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface RSAProductResponse extends DataServiceResponse {
  rsa_product_id: number
  cm_client_company_id: string
  media_id: MEDIA_ID
  account_id: string
  account_name: string
  campaign_id: string
  campaign_name: string
  adgroup_id: string
  adgroup_name: string
  rsa_product_score: number
  ad_strength: AD_STRENGTH
  pre_combined_rsa_id: string | null
  created_user: string
  created_at: string
  updated_user: string | null
  updated_at: string | null
  shipped_user: string | null
  shipped_at: string | null
  shipped_key: string | null
  assets: RSAProductAsset[]
}

interface RSAProductResponses extends DataServiceResponse {
  results: RSAProductResponse[]
}

interface RSAProductShipResponse extends DataServiceResponse {}

function convertResponseToModel(rpr: RSAProductResponse): RSAProduct {
  return {
    rsa_product_id: rpr.rsa_product_id,
    cm_client_company_id: rpr.cm_client_company_id,
    media_id: rpr.media_id,
    account_id: rpr.account_id,
    account_name: rpr.account_name,
    campaign_id: rpr.campaign_id,
    campaign_name: rpr.campaign_name,
    adgroup_id: rpr.adgroup_id,
    adgroup_name: rpr.adgroup_name,
    rsa_product_score: rpr.rsa_product_score,
    ad_strength: rpr.ad_strength,
    pre_combined_rsa_id: rpr.pre_combined_rsa_id,
    created_user: rpr.created_user,
    created_at: rpr.created_at,
    updated_user: rpr.updated_user,
    updated_at: rpr.updated_at,
    shipped_user: rpr.shipped_user,
    shipped_at: rpr.shipped_at,
    shipped_key: rpr.shipped_key,
    assets: rpr.assets,
  }
}

class RSAProductRepository {
  public async fetchRSAProductsByAMKey(data: {
        token: string,
        media_id: number,
        account_id: string,
        campaign_id: string,
        adgroup_id: string,
      }): Promise<RSAProduct[]> {
    const endpoint = `/rsa_products`
    return DataService.get<RSAProductResponses>({
      path: endpoint,
      param: {
        media_id: data.media_id,
        account_id: data.account_id,
        campaign_id: data.campaign_id,
        adgroup_id: data.adgroup_id,
      },
      token: data.token,
    }).then((rprs) => {
      return rprs.results.map((rpr) => {
        return convertResponseToModel(rpr)
      })
    })
  }

  public async fetchRSAProductByPK(data: {
    token: string,
    rsa_product_id: number,
  }): Promise<RSAProduct> {
    const endpoint = `/rsa_products/assets`
    return DataService.get<RSAProductResponse>({
      path: endpoint,
      param: {
        rsa_product_id: data.rsa_product_id,
      },
      token: data.token,
    }).then((rpr) => {
      return convertResponseToModel(rpr)
    })
  }

  public async shipRSAProduct(data: {
    token: string,
    rsa_product_id: number,
    user: string,
  }): Promise<void> {
    const endpoint = `/rsa_products/ship`
    return DataService.put<RSAProductShipResponse>({
      path: endpoint,
      param: {
        rsa_product_id: data.rsa_product_id,
        ship_user: data.user,
      },
      token: data.token,
    }).then(() => {
      return
    })
  }
}

export const rsaProductRepository = new RSAProductRepository()
