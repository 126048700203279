import {
  Account,
  Keyword,
  RequestKeywordId,
} from '@/stores/model/domain';
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor';
import {convertResponseToModel, KeywordResponse} from '@/stores/repository/keyword';

interface CheckResponse extends DataServiceResponse {
  is_valid: boolean;
  error_request_keyword_ids: RequestKeywordId[];
}

interface PostResponse extends DataServiceResponse {
  keywords: KeywordResponse[];
}

class BulkUploadKeywordRepository {

  public async checkExists(data: {
      token: string,
      user: string,
      requestKeywordIds: RequestKeywordId[],
      account: Account,
    }): Promise<RequestKeywordId[]> {
    const endpoint = `/keywords/bulk/check`;
    return DataService.post<CheckResponse>({
      path: endpoint,
      param: {
        request_keyword_ids: data.requestKeywordIds,
        user: data.user,
      },
      token: data.token,
    }).then((r) => {
      if (r.is_valid) {
        return data.requestKeywordIds;
      } else {
        const errorMessage = r.error_request_keyword_ids.map((errorRequestKeywordId) => {
          return `有効なキーワードが存在しません。AccountId:${errorRequestKeywordId.account_id} ` +
            `CampaignId:${errorRequestKeywordId.campaign_id} ` +
            `AdGroupId:${errorRequestKeywordId.adgroup_id} ` +
            `KeywordId:${errorRequestKeywordId.keyword_id}`;
        }).join('<br/>');
        return Promise.reject(errorMessage);
      }
    });
  }

  public async post(data: {
    token: string,
    user: string,
    requestKeywordIds: RequestKeywordId[],
    account: Account,
  }): Promise<Keyword[]> {
    const endpoint = `/keywords/bulk`;
    return DataService.post<PostResponse>({
      path: endpoint,
      param: {
        request_keyword_ids: data.requestKeywordIds,
        user: data.user,
      },
      token: data.token,
    }).then((ks) => {
      return ks.keywords.map((k) => {
        return convertResponseToModel(k);
      });
    });
  }

  public async delete(data: {
    token: string,
    user: string,
    requestKeywordIds: RequestKeywordId[],
    account: Account,
  }): Promise<Keyword[]> {
    const endpoint = `/keywords/bulk/delete`;
    return DataService.put<PostResponse>({
      path: endpoint,
      param: {
        request_keyword_ids: data.requestKeywordIds,
        user: data.user,
      },
      token: data.token,
    }).then((ks) => {
      return ks.keywords.map((k) => {
        return convertResponseToModel(k);
      });
    });
  }
}

export const bulkUploadKeywordRepository = new BulkUploadKeywordRepository()
