import {
  ASSET_TYPE,
  KeywordImpConv,
  LLMAsset,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface LLMAssetResponse extends DataServiceResponse {
  word: string
  asset_type: ASSET_TYPE
}

interface LLMAssetResponses extends DataServiceResponse {
  results: LLMAssetResponse[]
}

export function convertResponseToModel(llma: LLMAssetResponse, keyword: KeywordImpConv): LLMAsset {
  return {
    word: llma.word,
    asset_type: llma.asset_type,
    keyword,
  }
}

class LLMAssetRepository {
  public async fetchLLMAssets(data: {
        token: string,
        keyword: KeywordImpConv,
        prompt: string | null,
        lp_url: string | null,
        currentAssets: string[],
        assetType: ASSET_TYPE,
      }): Promise<LLMAsset[]> {
    const endpoint = `/llm_assets`
    return DataService.post<LLMAssetResponses>({
      path: endpoint,
      param: {
        account_id: data.keyword.account_id,
        media_id: data.keyword.media_id,
        campaign_id: data.keyword.campaign_id,
        adgroup_id: data.keyword.adgroup_id,
        keyword_id: data.keyword.keyword_id,
        prompt: data.prompt,
        lp_url: data.lp_url,
        current_assets: data.currentAssets,
        asset_type: data.assetType,
      },
      token: data.token,
    }).then((llmas) => {
      return llmas.results.map((llma) => {
        return convertResponseToModel(llma, data.keyword)
      })
    })
  }
}

export const llmAssetRepository = new LLMAssetRepository()
