import {
  Account,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface AccountsResponse extends DataServiceResponse {
  results: AccountResponse[]
}

interface AccountResponse extends DataServiceResponse {
  cm_client_company_id: string
  media_id: number
  account_id: string
  account_name: string
}

class AccountRepository {
  public async fetchAccounts(data: { token: string, cmClientCompanyId: string }): Promise<Account[]> {
    const endpoint = `/accounts/${data.cmClientCompanyId}`
    return DataService.getCache<AccountsResponse>({
      path: endpoint,
      param: { },
      token: data.token,
    }).then((as) => {
      return as.results.map((a) => {
        return {
          cm_client_company_id: a.cm_client_company_id,
          media_id: a.media_id,
          account_id: a.account_id,
          account_name: a.account_name,
        }
      })
    })
  }
}

export const accountRepository = new AccountRepository()
