<div>
    <h2><i class="fas fa-caret-right"></i>事前組み合わせAsset一括DL</h2>
    <div v-show="!!selectedClient" class="bulk_rsa_ad">
        <div class="">
            <Multiselect
                    class=""
                    placeholder="アカウント名で検索"
                    v-model="selectedAccountValue"
                    value-prop="value"
                    :options="selectAccountOptions"
                    label="label"
                    track-by="track_by"
                    :searchable="true"
                    :option-height="20"
                    :limit="1000"
                    @select="onSelectAccount">
            </Multiselect>
            <div class="account-id" v-if="!!selectedAccount">ACCOUNTID:{{ selectedAccount.account_id }}（{{ selectedAccount.media_id }}）</div>
        </div>
    </div>

    <div v-show="!!selectedAccount" class="bulk_rsa_ad">
        <div class="cr_table">
            <div>
                <button class="btn upload" v-bind:disabled="!canDownload" @click="onClickDownload">RSAAdダウンロード</button>
            </div>

            <p class="error" v-html="errorMessage"></p>

            <div class="create_content">
                <div class="input_create">
                    <textarea v-model="inputAdgroupIds" placeholder="アドグループidを入力"></textarea>
                </div>
            </div>
        </div>
    </div>
</div>
