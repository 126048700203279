<template src='./ProduceRSAShippingModal.html' />
<style lang="scss" src="@/components/pages/PWAssetCreate/Modals/ProduceRSAShippingModal/ProduceRSAShippingModal.scss"></style>
<script lang='ts'>
import {
  Account,
  AdGroup,
  ASSET_PINNED_FILED,
  ASSET_TYPE,
  KEYWORD_TYPE,
  NG_TYPE,
  NGWord,
  RSAProduct,
  RSAProductAsset,
  RSAProductWithAssets,
} from '@/stores/model/domain'
import {computed, defineComponent, ref} from 'vue'
import Multiselect from '@vueform/multiselect'
import {powerwordState} from '@/stores/state/powerword/powerword'
import {rsaProductRepository} from '@/stores/repository/rsa_product'
import {auth} from '@/stores/authorization-state'
import useTdUtils from '@/utils/td-utils'
import {Format} from '@/utils';
import {textLength} from '@/utils/validator'
import {DESCRIPTION_MAX_LENGTH, TITLE_MAX_LENGTH} from '@/constants';

const ProduceRSAShippingModal = defineComponent({
  components: {
    Multiselect,
  },
  setup(_, context) {
    const isActiveModal = ref(false)
    const inputFreeLabel = ref<string>('')
    const optRsaProductWithAssets = ref<RSAProductWithAssets | null>(null)
    const errorMessages = ref<string[]>([])

    const pinnedFieldNumFormat = (pinnedField: ASSET_PINNED_FILED) => {
      return Format.pinnedFieldNum(pinnedField)
    }
    const assetTypeFormat = (assetType: ASSET_TYPE) => {
      return Format.assetType(assetType)
    }

    const reloadShippingInfo = (rpWithAssets: RSAProductWithAssets) => {
      const selectedAdGroup = powerwordState.selectedAdGroup
      if (!selectedAdGroup) { return }
      optRsaProductWithAssets.value = rpWithAssets
      isActiveModal.value = true
      inputFreeLabel.value = ''
      powerwordState.setLoadingOn()
      // 納品確認モーダルを開いた時にバリデーションチェック
      rsaProductRepository.check({
        token: auth.token,
        rsa_product_assets: rpWithAssets.assets,
        adgroup: selectedAdGroup,
        free_label: inputFreeLabel.value.length > 0 ? inputFreeLabel.value : null,
      }).then((res) => {
        errorMessages.value = res.errors
        powerwordState.setLoadingOff()
      })
    }

    const ngCheckTypeFormatForRSA = (ngType: NG_TYPE) => {
      return Format.ngCheckTypeForRSA(ngType)
    }

    const labelPreview = computed(() => {
      const trackingId = optRsaProductWithAssets.value?.tracking_id ? `${optRsaProductWithAssets.value.tracking_id}` : 'KTD-TID-xxxxxxxxxx'
      const freeLabel = inputFreeLabel.value ? `;${inputFreeLabel.value}` : ''
      return 'AD検証(極RSA_V3)${submitted_date};${posted_date}納品_${seq_adg_posted_date};' +
          `${auth.employeeNumber};${trackingId}` + freeLabel
    })

    const isOverLength = (asset: RSAProductAsset) => {
      const length = textLength(asset.asset_text)
      return asset.asset_type == ASSET_TYPE.TITLE && length > TITLE_MAX_LENGTH ||
          asset.asset_type == ASSET_TYPE.DESCRIPTION && length > DESCRIPTION_MAX_LENGTH;

    }

    // バリデーションチェック
    // ラベル自由入力は、フロントエンドでもバリデーションする
    const isInvalidLabel = computed(() => {
      // やりとり(guam)側でタグ変換される文字列を排除
      return inputFreeLabel.value.match(/\$\{.*}/)
    })

    const onClickShipConfirm = () => {
      if (isInvalidLabel.value || errorMessages.value.length > 0) { return }
      const rsaProduct = optRsaProductWithAssets.value
      if (!rsaProduct) { return }
      if (!window.confirm('納品しますか？')) { return }

      // 納品実行
      powerwordState.setLoadingOn()
      rsaProductRepository.ship({
        token: auth.token,
        rsa_product_id: rsaProduct.rsa_product_id,
        free_label: inputFreeLabel.value.length > 0 ? inputFreeLabel.value : null,
        user: auth.employeeNumber,
      }).then(() => {
        powerwordState.setLoadingOff()
        context.emit('reloadHistory')
        closeProduceRSAShippingModal()
        alert('納品しました')
        return
      }).catch((e) => {
        alert('納品に失敗しました')
        powerwordState.setLoadingOff()
        return
      })
    }

    const closeProduceRSAShippingModal = () => {
      isActiveModal.value = false
    }

    return {
      optRsaProductWithAssets,
      isActiveModal,
      reloadShippingInfo,
      closeProduceRSAShippingModal,
      pinnedFieldNumFormat,
      assetTypeFormat,
      textLength,
      ngCheckTypeFormatForRSA,
      onClickShipConfirm,
      inputFreeLabel,
      labelPreview,
      isOverLength,
      isInvalidLabel,
      errorMessages,
    }
  },
})
export default ProduceRSAShippingModal
</script>
