import { reactive } from 'vue'
import {
  CmClientCompany,
  NGWord,
  CmClientCompanyOption,
} from '@/stores/model/domain'

interface CheckLoading {
  main: boolean,
  clientCompanies: boolean,
}

interface CheckState {
  loading: CheckLoading,
  // ClientCompany
  clientCompanies: CmClientCompany[],
  clientCompanyOptions: CmClientCompanyOption[],
  selectedClientCompany: CmClientCompany | null,
  // NGWord
  ngWords: NGWord[],
  // Method
  // main loading
  setLoadingOn: () => void,
  setLoadingOff: () => void,
  // ClientCompany
  setClientCompanies: (clientCompanies: CmClientCompany[]) => void,
  setIsLoadingClientCompanies: (bool: boolean) => void,
  setSelectedClientCompany: (clientCompany: CmClientCompany | null) => void,
  // NGWord
  setNGWords: (ngws: NGWord[]) => void,
}

export const checkState = reactive<CheckState>({
  loading: {
    main: false,
    clientCompanies: false,
  },
  // ClientCompany
  clientCompanies: [],
  clientCompanyOptions: [],
  selectedClientCompany: null,
  // NGWord
  ngWords: [],
  // Method
  // main loading
  setLoadingOn: () => {
    checkState.loading.main = true
  },
  setLoadingOff: () => {
    checkState.loading.main = false
  },
  // ClientCompany
  setClientCompanies: (clientCompanies: CmClientCompany[]) => {
    checkState.clientCompanies = clientCompanies
    checkState.clientCompanyOptions = clientCompanies.map((cc) => {
      return {
        value: cc,
        label: cc.cm_client_company_id + ' - ' + cc.cm_client_company_name,
        track_by: cc.cm_client_company_id + ':' + cc.cm_client_company_name + ':' + cc.account_ids_str,
      }
    })
  },
  setIsLoadingClientCompanies: (bool: boolean) => {
    checkState.loading.clientCompanies = bool
  },
  setSelectedClientCompany: (clientCompany: CmClientCompany | null) => {
    checkState.selectedClientCompany = clientCompany
  },
  // NGWord
  setNGWords: (ngws: NGWord[]) => {
    checkState.ngWords = ngws
  },
})
