import {
  CmClientCompany,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface CmClientCompanyResponses extends DataServiceResponse {
  results: CmClientCompanyResponse[]
}
interface CmClientCompanyResponse extends DataServiceResponse {
  cm_client_company_id: string
  cm_client_company_name: string
  account_ids_str: string | null;
}

class CmClientCompanyRepository {
  private clientsEndpoint: string = '/client_companies'

  public async fetchClientCompanies(data: { token: string }): Promise<CmClientCompany[]> {
    return DataService.getCache<CmClientCompanyResponses>({
      path: this.clientsEndpoint,
      param: { },
      token: data.token,
    }).then((cs) => {
      return cs.results.map((c) => {
        return {
          cm_client_company_id: c.cm_client_company_id,
          cm_client_company_name: c.cm_client_company_name,
          account_ids_str: c.account_ids_str,
        }
      })
    })
  }
}

export const cmClientCompanyRepository = new CmClientCompanyRepository()
