import {
  MEDIA_ID,
  NG_TYPE,
  NGWord,
} from '@/stores/model/domain'
import {DataServiceAccessor as DataService, DataServiceResponse} from '@/http/data-service/data-service-accessor'

interface NGWordResponses extends DataServiceResponse {
  results: NGWordResponse[]
}

interface NGWordResponse extends DataServiceResponse {
  ng_word_id: number
  cm_client_company_id: string
  media_id: MEDIA_ID | null
  account_id: string | null
  campaign_id: string | null
  adgroup_id: string | null
  ng_word: string
  replaced_word: string | null
  reason: string | null
  ng_type: NG_TYPE
  aitd_upload_id: string | null
  inspector_rule_id: string | null
  inspector_rule_source: string | null
  available_account_ids: string[]
  available_campaign_ids: string[]
  available_adgroup_ids: string[]
  created_user: string | null
  created_at: string | null
  updated_user: string | null
  updated_at: string | null
}

interface CheckResponse extends DataServiceResponse {
  error_ng_words: ErrorUploadNGWord[]
}

interface PostResponse extends DataServiceResponse {
}

export interface RequestUploadNGWord {
  row_num: number
  aitd_upload_id: string | null
  ng_word: string
  replaced_word: string | null
  reason: string | null
  ng_type: NG_TYPE
  media_id: MEDIA_ID | null
  account_id: string | null
  campaign_id: string | null
  adgroup_id: string | null
}

export interface ErrorUploadNGWord {
  row_num: number
  error_message: string
}

function convertResponseToModel(ngwr: NGWordResponse): NGWord {
  return {
    ng_word_id: ngwr.ng_word_id,
    cm_client_company_id: ngwr.cm_client_company_id,
    media_id: ngwr.media_id,
    account_id: ngwr.account_id,
    campaign_id: ngwr.campaign_id,
    adgroup_id: ngwr.adgroup_id,
    ng_word: ngwr.ng_word,
    replaced_word: ngwr.replaced_word,
    reason: ngwr.reason,
    ng_type: ngwr.ng_type,
    aitd_upload_id: ngwr.aitd_upload_id,
    inspector_rule_id: ngwr.inspector_rule_id,
    inspector_rule_source: ngwr.inspector_rule_source,
    created_user: ngwr.created_user,
    created_at: ngwr.created_at,
    updated_user: ngwr.updated_user,
    updated_at: ngwr.updated_at,
  }
}

class NGWordRepository {

  public async fetchNGWords(data: {
    token: string,
    cmClientCompanyId: string,
  }): Promise<NGWord[]> {
    const endpoint = `/ng_word/${data.cmClientCompanyId}`
    return DataService.get<NGWordResponses>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((ngwrs) => {
      return ngwrs.results.map((ngwr) => {
        return convertResponseToModel(ngwr)
      })
    })
  }

  public async fetchNGWordsIncludeBL(data: {
    token: string,
    cmClientCompanyId: string,
    mediaId: MEDIA_ID,
    accountId: string,
    campaignId: string,
    adgroupId: string,
  }): Promise<NGWord[]> {
    const endpoint = `/ng_word/${data.cmClientCompanyId}/include_bl/${data.mediaId}/${data.accountId}/${data.campaignId}/${data.adgroupId}`
    return DataService.get<NGWordResponses>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((ngwrs) => {
      return ngwrs.results.map((ngwr) => {
        return convertResponseToModel(ngwr)
      })
    })
  }

  public async deleteNGWord(data: {
    token: string,
    cmClientCompanyId: string,
    ngWord: NGWord,
  }): Promise<NGWord[]> {
    const endpoint = `/ng_word/${data.cmClientCompanyId}/${data.ngWord.ng_word_id}`
    return DataService.delete<NGWordResponses>({
      path: endpoint,
      param: {},
      token: data.token,
    }).then((ngwrs) => {
      return ngwrs.results.map((ngwr) => {
        return convertResponseToModel(ngwr)
      })
    })
  }

  public async check(data: {
    token: string,
    user: string,
    cmClientCompanyId: string,
    requestUploadNGWords: RequestUploadNGWord[],
  }): Promise<RequestUploadNGWord[]> {
    const endpoint = `/ng_word/check`
    return DataService.post<CheckResponse>({
      path: endpoint,
      param: {
        cm_client_company_id: data.cmClientCompanyId,
        upload_ng_words: data.requestUploadNGWords,
        updated_user: data.user,
      },
      token: data.token,
    }).then((cr) => {
      if (cr.error_ng_words.length <= 0) {
        return data.requestUploadNGWords
      } else {
        return Promise.reject(cr.error_ng_words)
      }
    })
  }

  public async postUpload(data: {
    token: string,
    user: string,
    cmClientCompanyId: string,
    requestUploadNGWords: RequestUploadNGWord[],
  }): Promise<void> {
    const endpoint = `/ng_word/upload`
    return DataService.post<PostResponse>({
      path: endpoint,
      param: {
        cm_client_company_id: data.cmClientCompanyId,
        upload_ng_words: data.requestUploadNGWords,
        created_user: data.user,
      },
      token: data.token,
    }).then((gar) => {
      return
    })
  }
}

export const ngWordRepository = new NGWordRepository()
